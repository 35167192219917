export const RtkqTagEnum = {
  AUTH_USER: "AUTH_USER",
  USERS: "USERS",
  INVESTMENTS: "INVESTMENTS",
  FEEDBACK: "FEEDBACK",
  WALLET: "WALLET",
  PROPERTY: "PROPERTY",
  TRANSACTION: "TRANSACTION",
};

export const Endpoints = {
  LOGIN: "/user/login",
  SIGNUP: "/user/register",
  USER_PROFILE: "/user/profile",
  UPDATE_PROFILE: "/user/update-profile",
  UPDATE_PASSWORD: "/user/password",
  CREATE_FEEDBACK: "/feedback",
  VALIDATE_REGISTRATION_OTP: "/user/validate-registration-otp",
  CREATE_WALLET: "/user/create-wallet",
  MY_WALLET: "/user/wallet",
  PROPERTY: "/property",
  INVESTMENTS: "/investment",
  INVEST: "/investment/invest",
  UPLOAD_PROFILE_PICTURE: "/user/upload-profile-pic",
  REQUEST_PASSWORD_RESET: "/user/request-password-reset",
  RESET_PASSWORD: "/user/reset-password",
  GENERATE_PAYMENT_LINK: "/wallet/payment-link",
  TRANSACTION_HISTORY: "/transaction/user",
  PORTFOLIO: "/user/portfolio-dashboard",
  BANK_LIST: "/wallet/bank-list",
  WITHDRAWAL: "/wallet/transfer-to-account",
};

export const ProfileSectionEnum = {
  AUTH: "AUTH",
  ASSESSMENT: "ASSESSMENT",
  BIO_DATA: "BIO_DATA",
  KYC: "KYC",
  SECURITY: "SECURITY",
  NEXT_OF_KIN: "NEXT_OF_KIN",
  MARKET_UPDATE: "MARKET_UPDATE",
};

export const RoutePath = {
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  SIGN_UP_VERIFICATION: "/signup/verification",
  SIGN_UP_SELF_ASSESSMENT: "/signup/self-assessment",
  SIGNUP_COMPLIANCE: "/signup/compliance",
  SIGNUP_COMPLETED: "/signup/complete",
  PROPERTY: "/property",
  PROPERTY_INFORMATION: "/property/information/:id",
  PORTFOLIO: "/portfolio",
  PORTFOLIO_INVESTMENTS: "/portfolio/investments",
  PORTFOLIO_INVESTMENTS_INFORMATION: "/portfolio/investments/:id",

  PROFILE: "/profile",
};
export const PropertyTypeEnum = {
  APARTMENT: "APARTMENT",
  LAND: "LAND",
  BUILDING: "BUILDING",
};

export const PROPERTY_TYPE = [
  { label: "Apartmemt", value: PropertyTypeEnum.APARTMENT },
  { label: "Land", value: PropertyTypeEnum.LAND },
  { label: "Building", value: PropertyTypeEnum.BUILDING },
];
export const TransactionStatusEnum = {
  SUCCESS: "SUCCESS",
  PENDING: "PENDING",
  FAILED: "FAILED",
  REVERSED: "REVERSED",
  ABANDONED: "ABANDONED",
};
